import axios from "axios";
const TOKEN = "Bearer 5bb4cb5e7b93858d18c0e18f85fb1376";

export default {
  async getInterventi(userID: string) {
    const data = new FormData();
    data.append("limit", "50");
    data.append("offset", "0");
    //data.append("where[tickets_reports_technician]", userID.toString());
    data.append(
      "where[]",
      `tickets_reports_id IN (SELECT tickets_reports_id FROM tickets_reports_tecnici WHERE users_id = ${userID.toString()})`
    );
    data.append("orderby", "tickets_reports_date");
    data.append("orderdir", "desc");

    const response = await axios.post("search/tickets_reports", data, {
      headers: {
        Authorization: TOKEN,
      },
    });
    localStorage.setItem("interventi", JSON.stringify(response.data.data));
    //console.log(response);
    return response.data.data;
  },

  async getInterventoDetail(id: string, userID: string) {
    const data = new FormData();
    //data.append("where[interventi_operatore]", userID.toString());
    //data.append("where[]", `tickets_reports_id IN (SELECT tickets_reports_id FROM tickets_reports_tecnici WHERE users_id = ${userID.toString()})`);
    data.append("where[tickets_reports_id]", id.toString());

    const response = await axios.post("search/tickets_reports", data, {
      headers: {
        Authorization: TOKEN,
      },
    });
    //console.log(response.data.data[0]);
    return response.data.data[0];
  },

  /**
   *
   * @param intervento array with intervento data
   * @returns intervento just created
   *
   * Salva intervento appena creato
   */
  async saveIntervento(intervento) {
    const response = await axios.post(`create/tickets_reports`, intervento, {
      headers: {
        Authorization: TOKEN,
      },
    });
    //console.log(response);
    return response;
  },

  /**
   *
   * @param intervento array with intervento data
   * @returns intervento just created
   *
   * Aggiorna un intervento con la preferenza dell'invio mail al cliente, effettuato dal dettaglio intervento
   */
  async sendCustomerMail(email_status, idIntervento) {
    const response = await axios.post(
      `edit/tickets_reports/${idIntervento}`,
      email_status,
      {
        headers: {
          Authorization: TOKEN,
        },
      }
    );
    //console.log(response);
    return response;
  },

  async saveOperatorSign(firma: string, idIntervento: string) {
    const data = new FormData();
    data.append("tickets_reports_firma_operatore_b64", firma);

    const response = await axios.post(
      `edit/tickets_reports/${idIntervento}`,
      data,
      {
        headers: {
          Authorization: TOKEN,
        },
      }
    );
    //console.log(response);
    return response;
  },

  async saveCustomerSign(firma: string, idIntervento: string) {
    const data = new FormData();
    data.append("tickets_reports_firma_cliente_b64", firma);

    const response = await axios.post(
      `edit/tickets_reports/${idIntervento}`,
      data,
      {
        headers: {
          Authorization: TOKEN,
        },
      }
    );
    //console.log(response);
    return response;
  },

  async savePhotos(photos, intervento) {
    const data = new FormData();
    data.append("intervento_id", intervento);
    data.append("photos", JSON.stringify(photos));

    const response = await axios.post(
      `https://crm.sicurity.it/custom/app/saveImage`,
      data
    );
    //console.log(response);
    return response;
  },
};
